import './App.css';
import {Animator, batch, Fade, Move, MoveOut, ScrollContainer, ScrollPage, Sticky} from "react-scroll-motion";
import BackgroundMusic from "./BackgroundMusic";
import ImageSlider from "./ImageSlider";
import ImageOne from "./ImageOne";

function App() {
  const FadeUp = batch(Fade(), Move(), Sticky());

  return (
      <ScrollContainer style={{margin: "0 auto", width: "360px"}}>
        <ScrollPage>
          <BackgroundMusic url="https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/background_music.mp3"/>
          <Animator animation={FadeUp}>
            <div style={{width: "360px"}}>
              <p
                  style={{
                    fontSize: "35px",
                    fontWeight: "bold",
                    fontFamily: "Song Myung, serif",
                    textShadow: "0 0 5px rgba(255, 255, 255, 0.7)",
                    marginBottom: "10px"
                  }}>효림아 안녕 😀
              </p>
              <div>
              <span style={{fontSize: "15px"}}>
              갑자기 웹사이트를 공유해서 놀랐지<br/>
              구상은 만나면서 나중에 해야지 했었던 건데,<br/>
              이런 상황에서 만들게 될 줄은 정말 꿈에도 몰랐네<br/><br/>

              해볼 수 있는데까지는 해봐야될 것 같아서.<br/>
              정리를 하다가, 정리가 잘되지 않아서 만들어보게 되었어.<br/><br/>

              우리가 함께했던 순간들을 정리해보다가,<br/>
              나만이 할 수 있는 방법으로, 나만이 표현할 수 있는 방법으로<br/>
              내 마음을 전달해보고 싶다라는 생각이 들었어<br/><br/>

              보는 방법은 간단해<br/>
              왼쪽 상단 배경음악을 재생하고, 스크롤해서 내리면 돼!<br/>
              카카오 인앱 브라우저보다는 크롬으로 보는 걸 추천해!<br/>
                그리고 조금 짤릴텐데, 웹으로 봐도 좋구!
            </span>
              </div>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
          <Animator animation={batch(FadeUp, MoveOut(0, -1400))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>처음..! 소마 연말 파티</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2023.<b style={{color: "red"}}>12.21</b></p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  우리가 처음만났던 날이네<br/>
                  진짜 이 때는 우리 앞에 다가올 미래를 몰랐었지<br/><br/>
                  처음 봤던 순간하고 치지직 형들 팀에서 뒤로 걸어나오던 모습들이 생각나<br/>
                  진짜 설레었던 기억이 난다!
                </span>
              </div>
              <ImageOne slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2023_12_21.png',
                    description: '처음 만난 날. 단체 사진',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>사귀기 시작했던 날!</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.<b style={{color: "red"}}>1.21</b></p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  처음 보자마자 손잡아줬던 순간.<br/>
                  긴장해서 식은땀도 나고 식당도 잘못 찾아들어가구<br/>
                  너는 그 와중에도 되게 침착하구 자연스러웠던 것 같은데 나는 완전 정신이 나가있었네! ㅋㅋㅋ<br/><br/>

                  같이 노래 들으면서 석촌호수 근처 돌다가<br/>
                  카페에서 만나보자고 했던 기억과 뒤에 갔던 식당에서도<br/>
                  진짜 행복해서 어쩔줄을 몰라했던 기억이 나<br/>
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/21_2.jpeg',
                    description: '진도켄에 이은 사케!',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_21_4.png',
                    description: '진짜 부끄러워하는 나',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/21_1.jpeg',
                    description: '젓가락질 연습하려고 찍은 사진',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_21_5.png',
                    description: '손가락 차이 두배..!',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>화장하고 셀카 보여준 날!</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.1.25</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  이 때 사진 보내줬을 때,<br/>
                  아마 거의 처음으로 셀카를 보내줬던 때인 것으로 기억해<br/><br/>

                  이 때 진짜 행복했던 기억이 나서 넣어봤어!
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_25_1.png',
                    description: '화장한 효림이!',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>히사이시조<br/>음악회🎻 본 날!</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.1.27</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  그렇게 좋아하는 히사이시조 공연을 같이 본 날이지<br/>
                  진짜 맘에 안들까봐 얼마나 걱정을 했었는지 모르겠네 ㅋㅋㅋ<br/><br/>
                  근데 옆에서 진짜 너무 좋아해주길래 엄청 행복했어<br/>
                  디테일한 부분들 다 듣는거 옆에서 보면서 놀랐더 기억도 있구<br/>
                  그리고 무엇보다! 중요했던 건 이날 배경화면 사진을 찍었던 날이었지 ㅎㅎ
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_27_3.png',
                    description: '히사이시조 공연',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_27_1.png',
                    description: '레전드 사진 1',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_27_2.png',
                    description: '레전드 사진 2',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>새우까기 장인 김효림</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.1.30</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  진짜 지금 다시봐도 놀랍단 말이야 ㅋㅋㅋㅋㅋ<br/>
                  처음에는 이걸 어떻게 하려고..? 라는 생각이었는데<br/>
                  슥슥 해내는 거 보고 진짜 완전 반했던 기억이 나<br/><br/>
                  이 날 처음으로 남색 남방 입고 갔는데 되게 좋아해줘서 행복했던 기억도 난당<br/>
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_01_30_11.png',
                    description: '새우 깐 거 인증샷',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>예슬, 민회와 저녁식사!<br/>그리고 같이 ⚽️ 본 날</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.2</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  살면서 처음으로 여자친구의 친구들하고 식사를 하게 돼서<br/>
                  진짜 바짝 긴장했던 기억이 나<br/><br/>
                  같이 밥먹으면서 몰래 아래로 손잡고 있었던 게 진짜 설레었던 기억도 난다!☺️<br/><br/>
                  그리고 끝에는 집가기 아쉬워서 같이 위스키 마시면서 축구봤었는데 결국 역전해서 진짜 재미있었네 ㅎㅎ

                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_03_4.png',
                    description: '예슬이, 민회와 함께 식사',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_03_3.png',
                    description: '운좋게 축구를 같이 보게 된..!',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_03_2.png',
                    description: '인증샷!',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_03_1.png',
                    description: '와플(?) 열심히 커팅중',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>자동차 극장<br/>전시,캠핑 카페!🔥</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.3</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  처음으로 사귀고 나서 멀리 여행같이 가본 날이었지
                  자동차 극장에서 같이 안고 있었던 거랑,<br/>
                  우연히 갔던 카페가 분위기랑 전시해놓은 게 너무 좋아서<br/>
                  꼭 다시 가보자고 했는데 결국은 다시 못가서 너무 아쉬운 마음이 들었어<br/>
                  마지막은 카더가든 음악이 인상 깊었던 캠핑장 분위기의 카페!<br/>
                  여기도 참 좋았다 그치?!
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/02-03-1.png',
                    description: '인스타 감성 시도해 본 사진',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/02-03-2.png',
                    description: '✌️효림',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/02-03-3.png',
                    description: '양치는 못참지!',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_04_3.png',
                    description: '예쁜 사진 1',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_04_4.png',
                    description: '예쁜 사진 2',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>이틀 연속 Facetime..!</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.6~2.7</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  진짜 Facetime 할 때, 너무 부끄러워서 일부러 앞에 모니터만 바라보고 있었던 기억이 나 ㅋㅋㅋ<br/>
                  진짜 엄청 떨렸어..! 시간 가는 줄 모르고 이 얘기 저 얘기 참 많이 나눴다 그치?!
                  <br/><br/>
                  그리고 원격 먹방!! 사진처럼 머리 묶은 사진에 반해서 저렇게 띄워놓고 한동안 있었던 기억이 나네 ㅎㅎ<br/>
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-6-1.png',
                    description: '빵터진 효림이😂',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-6-2.png',
                    description: '부끄러워서 모니터 쳐다보는 중',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-6-3.png',
                    description: '너무 좋아서 사진 올려놓고 계속 보는중',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>립밤 선물에 감동..<br/>그리고 볼링!🎳</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.8</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  이 선물은 내가 받은 선물 중에 거짓말 안하고 최고로 좋았던 선물이였어<br/>
                  세심하게 챙겨주는 게 엄청 큰 감동이었지<br/>
                  나 챙겨주는 거 좋아했네..!!<br/><br/>
                  그리고 청첩장 모임 끝날 때까지 기다렸다가 볼링장도 갔었지<br/>
                  이 날 땀났던 게 사실 내가 볼링 너무 못칠까봐 긴장해서 땀을 흘린거였다라는..!ㅋㅋㅋ<br/>
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-8-1.png',
                    description: '좋은 향이 나는 선물!',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-8-2.png',
                    description: '글씨체마저 꼼꼼. 세심한 효림이',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-8-3.png',
                    description: '볼링치는 효림이',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>아직 트렁크에 있는 🖥,️<br/>
                여의도 데이트</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.9</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  이건 데이트한 것보다도 우리의 바로 그녀석..! 모니터를 설치했던 날이었어 ㅎㅎ<br/>
                  (아직 차에 있는..)<br/><br/>
                  그리고 여의도에 가서 밥도 먹고, 카페도 가구, 저녁에는 탕탕이랑 해물 라면도 먹었던 날이었지!<br/>
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_09_3.png',
                    description: '모니터 설치하고 저녁에 인증샷! ㅋㅋ 귀엽다',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_09_1.png',
                    description: '메뉴 네 개(?) 시킨 것보고 놀라했던 날 ㅋㅋ',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>초콜릿 준 날! 💝</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.13</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  고디바 초콜릿과 맛있었던 음식들!<br/>
                  머리를 뒤로 포니테일(?)로 묶고온 모습 보고 땀을 엄청 흘린 기억이 난다 ㅎㅎㅎ<br/><br/>
                  좋은 사진들도 많이 찍었구, 24시 카페가서 회의도 하구!! 이 때가 분노의 질주 택시 아저씨 만났던 날이었나? ㅋㅋ<br/>
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2-13-1.png',
                    description: '구도가 미친 사진!',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_13_2.png',
                    description: '좋아해줬던 사진 ㅎㅎ',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>표정 장인 효림이와<br/>정자 토나리 스시🍣</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.2.17</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  이 날 기억나는 건, 차가 갑자기 고장나서 당황했던 것!<br/>
                  진주 반지가 진짜 너무 잘어울려서 놀랐던 것!<br/>
                  귀여운 표정을 너무 잘지어서 귀여웠던 것!<br/>
                  내 인생 가장 맛있었던 토나리 스시!<br/>
                  이렇게 여러 기억들이 생각나네<br/>
                  그 중에서도 아래 사진에 있는 수수카페에 가서 같이 앉아있었던 게
                  진짜 행복했던 것 같아 ㅎㅎ
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_17_1.png',
                    description: '이날도 나는 어색한 표정이네 ㅋㅋㅋ',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_02_17_2.png',
                    description: '한동안 내 배경화면을 차지했던 그 표정!',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>파전집!<br/>용산에서 파묘본 날!</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.3.1~3.2</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  진짜 파전하고 막걸리는 말도 안됐던 것 같아<br/>
                  결국 다시 못먹은 게 너무 아쉽네 ㅠㅠ<br/><br/>
                  이 날 막걸리 먹어서 결국 같이 자고<br/>
                  (내 코골이 때문에 고생했던 게 기억나네. 미안했어 🥲<br/><br/>
                  다음날 파묘도 되게 재밌게 봤던 것 같네 ㅎㅎ<br/>
                  아 그리고 나 이때 같이 산 치마 결국 못봤어..ㅠ
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_03_01_1.png',
                    description: '파전 주는 중!',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_03_02_1.png',
                    description: '파묘 티켓!',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/2024_03_02_2.png',
                    description: '파묘 보기 전 카페에서',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>율동 공원🏞️<br/>한강에서 본 그 영화</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.3.16~3.17</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  율동 공원도 좋았지만, 결국 우리가 같이 봤던 비포 선 라이즈..<br/>
                  진짜 우리랑 사연이 많은 영화를 끝내 본 날이었지<br/><br/>
                  그리고 마지막 사진은 내가 매번 감동받은<br/>피곤할까봐 직접 커피 만들어주는 효림이야
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/3-16-1.png',
                    description: '식혜 맛집',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/3-16-2.png',
                    description: '율동 공원에서 앙',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/3-16-3.png',
                    description: '율동 공원에서 한 장 더!',
                  },
                ]
              }/>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/3-17-1.png',
                    description: '그 영화 ㅎㅎ..',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/3-17-2.png',
                    description: '커피 만드는 중🥰',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>벚꽃 구경 🌸<br/>
                그리고 마스크팩! 🤿</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.4.2</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  내 인생샷 찍어주겠다고 엄청 열심히 이리저리 찍어주던 모습이 눈에 훤하네<br/>
                  진짜 감동이었어..<br/><br/>
                  벚꽃도 같이 보구, 그 날 마스크팩도 같이하구<br/>
                  행복한 하루였네 ㅎㅎ
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/4-2-1.png',
                    description: '같이 한 장',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/4-2-2.png',
                    description: '찍어준 사진 중 내가 좋아하는 사진이랄까ㅎ',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/4-2-3.png',
                    description: '마스크팩 같이!',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>대망의 SWM🚀</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.4.6</p>
              <div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px", marginBottom: "20px"}}>
                <span>
                  마음이 쉽지 않은 날이였어서 온전히 설렘을 느끼지는 못했지만
                  그래도 이렇게 사진으로 다시 보니까 감회가 새롭달까 ㅎㅎ<br/><br/>
                  오뎅이랑 양꼬치가 생각이 나네!<br/><br/>
                  설렘과 슬픔이 공존했던 날이었네
                </span>
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/4-6-1.png',
                    description: '나가기 전 엘베 앞에서 같이',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/4-6-2.png',
                    description: '밝게 웃고 있는 효림이',
                  },
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/4-6-3.png',
                    description: '행복하게 나왔어!',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <p style={{
                fontSize: "35px",
                fontWeight: "bold",
                fontFamily: "Song Myung, serif",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.7)"
              }}>그리고 지금..</p>
              <p style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>2024.4.30 <b style={{color: "red"}}>( D+100 )</b> ~
              </p>
              <div style={{fontSize: "15px",marginTop: "10px", marginBottom: "20px"}}>
                우리가 만났던 시간이 상대적으로는 짧은 시간일 수는 있지만,<br/>
                이렇게 정리해서 보니까 정말 누구보다도<br/><br/>행복하게. 뜨겁게. 슬프게.<br/>
                만났던 것 같아<br/><br/>
                이게 정말 내 마지막 메세지일 수도 있을 것 같아 슬프지만<br/>
                그래도 이제는 더이상 후회는 없을 것 같아<br/>
                어째되든, 100일은 꼭 기념해보고 싶었어! ㅎㅎ<br/><br/>

                이 다음 페이지에는 내가 하고 싶은 말을 적어놨으니까<br/>
                봐주면 좋겠네!<br/><br/>
                그럼 안뇽😀
              </div>
              <ImageSlider slides={
                [
                  {
                    image: 'https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/last.png',
                    description: '.',
                  },
                ]
              }/>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{margin: "0 auto", width: "360px", flex: "display", flexDirection: "column"}}>
              <div style={{fontWeight: "bold", lineHeight: "30px", textAlign: "right"}}>
                <span>
                  운명 같은 만남 너무 아픈 결말<br/>
난 이 소설의 끝을 다시 써보려 해<br/>
내 한 권의 사랑 마지막 장면엔<br/>
네가 있어야 해 그래야 말이 돼<br/><br/>

여기야 우리가 이별한 그 슬픈 페이지<br/>
내 앞에서 네가 서서 울고 있어<br/><br/>

너에게 묻고 싶어 너만 괜찮다면<br/>
난 이 소설의 끝을 다시 써보려 해<br/>
내 한 권의 사랑 마지막 장면엔<br/>
네가 있어야 해 그래야 말이 되니까<br/>
                </span>
              </div>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(FadeUp, MoveOut(0, -1000))}>
            <div style={{width: "360px"}}>
              <span style={{lineHeight: "40px"}}>
                <div style={{fontFamily: "Song Myung, serif", fontSize: "20px"}}>
                <b>
                '김효림과 류동훈'<br/>이라는 소설의<br/>마지막 장면에는<br/>
                너가 있으면 좋겠어<br/>
                  효림아<br/>
                </b>
                  </div>
              </span>
              <div style={{width: "360px", margin: "0 auto", marginTop: "20px", textAlign: "right"}}>
                <img src="https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/rose.png" style={{width: "70%"}}/>
              </div>
              <div style={{width: "360px", margin: "0 auto", marginTop: "-20px", marginRight: "-20px", marginBottom: "-100px", textAlign: "right"}}>
                <img src="https://d2cxdjqe8pmpe4.cloudfront.net/hyorim/donghun_1.png" style={{width: "40%"}}/>
              </div>
            </div>
          </Animator>
        </ScrollPage>
      </ScrollContainer>);
}

export default App;
