import React, {useState} from 'react';
import AudioPlayer from 'react-audio-player';
import {FaVolumeMute, FaVolumeUp} from 'react-icons/fa';

const BackgroundMusic = ({ url }) => {
  const [muted, setMuted] = useState(false);
  const [playing, setPlaying] = useState(true);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  return (
      <div className="background-music-container">
        <AudioPlayer
            src={url}
            autoPlay={true}
            loop
            controls={true}
            // muted={false}
            // playing={true}
            style={{opacity : "0.5"}}
        />
        {/*<button className="music-control" onClick={toggleMute}>*/}
        {/*  {muted ? <FaVolumeMute /> : <FaVolumeUp />}*/}
        {/*</button>*/}
        {/*<button className="music-control" onClick={togglePlay}>*/}
        {/*  {playing ? <FaPause /> : <FaPlay />}*/}
        {/*</button>*/}
      </div>
  );
};

export default BackgroundMusic;
