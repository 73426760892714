import React from 'react';

const ImageSlider = ({slides}) => {
  if (slides.length === 1) {
    return (
        <div className="container-1">
          <div className="slider-1">
            <img
                style={{margin: '0 auto', width: '360px', objectFit: "cover"}}
                key={"first"}
                src={slides[0].image}
                className="item-1"
                alt={slides[0].description}
            />
            <div
                className="description"
                style={{
                  fontSize: '15px',
                  textAlign: 'right',
                  marginTop: '10px',
                  marginLeft: `${10}px`,
                  color: 'white'
                }}
            >
              {slides[0].description}
            </div>
          </div>
        </div>
    );
  } else if (slides.length === 2) {
    return (
        <div className="container">
          <div className="slider">
            {slides.map((slide, index) => (
                <img
                    style={{margin: '0 auto', width: '360px', objectFit: "cover"}}
                    key={index} src={slide.image} className="item" alt={`Slide ${index}`}
                    alt={slide.description}
                />
            ))}
            {slides.map((slide, index) => (
                <div className="description"
                     style={{fontSize: '15px', textAlign: 'right', marginTop: '10px', marginLeft: `${index * 360 + 10}px`, color: 'white'}}>
                  {slide.description}
                </div>
            ))}
          </div>
        </div>
    )
  } else if (slides.length === 3) {
    return (
        <div className="container-3">
          <div className="slider-3">
            {slides.map((slide, index) => (
                <img
                    style={{margin: '0 auto', width: '360px', objectFit: "cover"}}
                    key={index} src={slide.image} className="item-3" alt={`Slide ${index}`}
                    alt={slide.description}
                />
            ))}
            {slides.map((slide, index) => (
                <div className="description"
                     style={{fontSize: '15px', textAlign: 'right', marginTop: '10px', marginLeft: `${index * 360 + 10}px`, color: 'white'}}>
                  {slide.description}
                </div>
            ))}
          </div>
        </div>
    )
  } else {
    return (
        <div className="container">
          <div className="slider">
            {slides.map((slide, index) => (
                <img
                    style={{margin: '0 auto', width: '360px', objectFit: "cover"}}
                    key={index} src={slide.image} className="item" alt={`Slide ${index}`}
                    alt={slide.description}
                />
            ))}
            {slides.map((slide, index) => (
                <div className="description"
                     style={{fontSize: '15px', textAlign: 'right', marginTop: '10px', marginLeft: `${index * 360 + 10}px`, color: 'white'}}>
                  {slide.description}
                </div>
            ))}
          </div>
        </div>
    )
  }
};

export default ImageSlider;
