import React from 'react';

const ImageOne = ({slides}) => {
  return (
      <div className="container-one">
        <div className="slider-one">
          {slides.map((slide, index) => (
              <img
                  style={{margin: '0 auto', width: '100%', height: '300px', objectFit: "cover"}}
                  key={index} src={slide.image} className="item-one" alt={`Slide ${index}`}
                  alt={slide.description}
              />
          ))}
          {slides.map((slide, index) => (
              <div className="description"
                   style={{fontSize: '15px', textAlign: 'right', marginTop: '10px', marginLeft: `${index * 360 + 10}px`, color: 'white'}}>
                {slide.description}
              </div>
          ))}
        </div>
      </div>
  );
};

export default ImageOne;
